
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    padding: 50px 20px;
    background-color: $bestbabyformulalawyers-purple-dark;
    @apply mx-auto flex flex-col justify-between items-start;
    color: white;

    h2 {
        @apply text-lg mb-4;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        @include md {
            @apply text-2xl;
        }
    }
}
